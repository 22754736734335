function Home() {
  return (
    <div>
      <h2 className="text-2xl my-12 font-semibold">Home</h2>
      <p>This is the homepage</p>
    </div>
  );
}

export default Home;
