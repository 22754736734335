function Projects() {
  return (
    <div>
      <h2 className="text-2xl my-12 font-semibold">Projects</h2>
      <p>This is the Projects page</p>
    </div>
  );
}

export default Projects;
